.carousel {
  max-height: 48rem;
  max-width: 710px !important;
  border-radius: 0 8px 8px 0 !important; 
}

.carousel .thumbs-wrapper {
  margin: 0 !important;
}

@media (max-width: 1919px) {
  .carousel {
    max-height: 48rem;
  }
}

@media (max-width: 1023px) {
  .carousel {
    border-radius: 0px !important;
    max-width: 100% !important;
  }
}

.carousel .control-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem !important;
}

.carousel .control-dots .dot {
  height: 10px !important;
  width: 10px !important;
  background: #464649 !important;
  opacity: 0.4 !important;
  box-shadow: none !important;
}

.carousel .control-dots .dot:hover,
.carousel .control-dots .dot.selected {
  opacity: 0.6 !important;
}

.carousel .control-dots .dot.selected {
  height: 14px !important;
  width: 14px !important;
}

